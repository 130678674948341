<template>
  <main>
    <Row :gutter="15">
      <Col :span="10">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
          <Button icon="md-add-circle" @click="create" v-if="Auth([ 'MemberCard', 'create' ])">添加</Button>
          <Button icon="md-download" @click="exportFile" v-if="Auth([ 'MemberCard', 'export' ])">导出</Button>
        </ButtonGroup>
      </Col>
      <Col :span="7" class="text-right">
        <DatePicker
          type="daterange"
          placeholder="注册时间"
          @on-change="filterDate"
          style="width: 250px"
        ></DatePicker>
      </Col>
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>
    <Divider/>
    <Tabs type="card" @on-click="changeChannel">
      <TabPane :label="item.name" :name="item.id" v-for="(item,index) in channels" :key="index"></TabPane>
    </Tabs>
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>
  </main>
</template>

<script>
import buildURL from "axios/lib/helpers/buildURL";
export default {
  data() {
    return {
      searches: {
        number: "卡号"
      },
      channels: [],
      member_card_channel_id: 0,
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "渠道",
          key: "channel",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h("span", params.row.channel.name);
          }
        },
        {
          title: "卡号",
          key: "number",
          sortable: "custom",
          align: "center",
          minWidth: 140
        },
        {
          title: "密码",
          key: "password",
          sortable: "custom",
          align: "center",
          minWidth: 140
        },
        {
          title: "是否已激活",
          key: "status",
          sortable: "custom",
          align: "center",
          minWidth: 140,
          render: (h, params) => {
            return h("span", params.row.status ? "是" : "否");
          }
        },
        {
          title: "激活时间",
          minWidth: 180,
          key: "activated_at",
          align: "center",
          sortable: "custom",
           render: (h, params) => {
            return h("span", params.row.status ? params.row.activated_at : "-");
          }
        },
        {
          title: "激活用户",
          key: "user",
          sortable: "custom",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h(
              "a",
              {
                class: "flex flex-align-center",
                on: {
                  click: () => {
                    if (params.row.user) {
                      this.$router.push("/user/detail/" + params.row.user.id);
                    }
                  }
                }
              },
              [
                h("img", {
                  attrs: {
                    src: params.row.user
                      ? params.row.user.avatar
                        ? params.row.user.avatar
                        : require("@/assets/no-avatar.jpg")
                      : "",
                    width:'48',
                    height:'48'
                  },
                  class: "avatar mr"
                }),
                h("p", params.row.user ? params.row.user.nickname : "无")
              ]
            );
          }
        },
        {
          title: "有效期",
          key: "valid_days",
          minWidth: 120,
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("span", params.row.valid_days + "天");
          }
        },
        {
          title: "渠道价",
          minWidth: 120,
          key: "unit_price_channel",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h(
              "p",
              { class: "text-price" },
              params.row.unit_price_channel
            );
          }
        },
        {
          title: "零售价",
          minWidth: 120,
          key: "unit_price_retail",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h(
              "p",
              { class: "text-price" },
              params.row.unit_price_retail
            );
          }
        },
        {
          title: "状态",
          key: "blocked",
          sortable: "custom",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            return h("span", params.row.blocked ? "已封禁" : "正常");
          }
        },
        {
          title: "添加时间",
          minWidth: 180,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "操作",
          minWidth: 110,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (this.Auth(["MemberCard", "update"])) {
              html.push(
                h(
                  "Button",
                  {
                    props: {
                      type: "warning",
                      size: "small",
                      icon: "md-warning"
                    },
                    class: "mg-lr-xs",
                    on: {
                      click: () => {
                        this.blocked(params.row);
                      }
                    }
                  },
                  "封禁/解封"
                )
              );
            }

            // if (this.Auth(["MemberCardChannel", "delete"])) {
            //   html.push(
            //     h("Button", {
            //       props: {
            //         type: "error",
            //         size: "small",
            //         icon: "md-trash"
            //       },
            //       class: "mg-lr-xs",
            //       on: {
            //         click: () => {
            //           this.remove(params.row);
            //         }
            //       }
            //     })
            //   );
            // }

            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadChannels();
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        member_card_channel_id:this.member_card_channel_id,
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/memberCard/list", { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
    loadChannels() {
      this.$http.get("/memberCardChannel/list").then(res => {
        res.data.sort(function(a,b){
          return a.id-b.id
        })
        this.channels = res.data;
        if(res.data.length>0){
          this.changeChannel(res.data[0].id)
        }
      });
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    filterDate(date) {
      this.begin_date = date[0];
      this.end_date = date[1];
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    changeChannel(val) {
      this.member_card_channel_id = val;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    create() {
      this.$router.push("/member-card/create");
    },
    update(item) {
      this.$router.push("/member-card/update/" + item.id);
    },
    blocked(item) {
      this.$Modal.confirm({
        title: "封禁或解封",
        content: "确定要封禁或解封该会员卡吗?",
        onOk: () => {
          this.$http.post("/memberCard/blocked/id/" + item.id).then(res => {
            this.loadData();
          });
        }
      });
    },
    remove(item) {
      this.$Modal.confirm({
        title: "确定要删除该渠道吗？",
        content: item.name,
        onOk: () => {
          this.$http.post("/memberCard/delete/id/" + item.id).then(res => {
            this.loadData();
          });
        }
      });
    },
    exportFile() {
      let params = {
        member_card_channel_id:this.member_card_channel_id,
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        begin_date: this.begin_date,
        end_date: this.end_date,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit,
        token: window.localStorage.getItem("token")
      };

      let url = this.$http.defaults.baseURL + "/memberCard/export?";

      window.open(buildURL(url, params));
    }
  }
};
</script>